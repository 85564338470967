
.create-examination {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .examination-header {
    display: flex;
    justify-content: flex-end;
  }
}
